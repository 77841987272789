<div class="card-modal">
    <header class="card-modal-header">
        <h4 class="card-modal-title">
            {{ 'MODAL_VEHICLE_STATUS.TITLE' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form class="mx-1" [formGroup]="form">

            <div class="title-small">{{'MODAL_VEHICLE_STATUS.START_SUSPECTION' | translate}}</div>
            <div class="row m-0 pb-2">
        
                <div class="col-sm-12 col-md-6 col-xl-6 form-group pb-2">
                    <label for="disableFrom" class="form-label">{{'START_DATE' | translate}}</label>
                    <input-date-picker formControlName="disableFrom"></input-date-picker>
                </div>
    
                <div class="col-sm-12 col-md-6 col-xl-6 form-group">
                    <label for="disableFromTime" class="form-label">{{'START_TIME' | translate}}</label>
                    <input type="time" class="form-control" id="disableFromTime" [placeholder]="'SELECT_TIME' | translate"
                        formControlName="disableFromTime" >
                </div>
    
            </div>

            <div class="title-small">{{'MODAL_VEHICLE_STATUS.END_SUSPECTION' | translate}}</div>
            <div class="row m-0">
        
                <div class="col-sm-12 col-md-6 col-xl-6 form-group pb-2">
                    <label for="disableTo" class="form-label">{{'END_DATE' | translate}}</label>
                    <input-date-picker formControlName="disableTo"></input-date-picker>
                </div>
    
                <div class="col-sm-12 col-md-6 col-xl-6 form-group">
                    <label for="disableToTime" class="form-label">{{'END_TIME' | translate}}</label>
                    <input type="time" class="form-control" id="disableToTime" [placeholder]="'SELECT_TIME' | translate"
                        formControlName="disableToTime" >
                </div>
    
            </div>

            <div class="mx-2">
                <div class="form-label">{{'MODAL_VEHICLE_STATUS.NOTE' | translate}}</div>                
                <textarea class="form-control" formControlName="note"></textarea>
            </div>

        </form>
    </content>
    
    <footer>
        <div class="d-flex gap-3 justify-content-center mt-3">
            <div type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
                {{ 'ACTIONS.CANCEL' | translate }}
            </div>
    
            <button type="submit" class="btn-gs-primary " 
                [disabled]="form.invalid" (click)="onSubmit()">
                {{ 'ACTIONS.SAVE' | translate }}
            </button>
        </div>
    </footer>
</div>