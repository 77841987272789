import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { InputDatePickerComponent } from 'src/app/shared/components/inputs/input-date-picker/input-date-picker.component';
import { VehicleDisable, VehicleDisableRequest } from 'src/app/shared/models/vehicle/vehicle-disable';
import { Subject, takeUntil } from 'rxjs';
@Component({
    selector: 'vehicle-suspension-program-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        InputDatePickerComponent
    ],
    templateUrl: './vehicle-suspension-program-modal.component.html',
    styleUrls: ['./vehicle-suspension-program-modal.component.scss']
})

export class VehicleSuspensionProgramModalComponent {

  @Input() vehicleId: string;
  @Input() vehicleDisableId: string;

  form: FormGroup;

  private _destroy$ = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _vehiclesService: VehiclesService,
    private _dateUtilsService: DateUtilsService,
    private _communicationService: CommunicationService,
  ) {
    this.form = new FormGroup({
      disableFrom: new FormControl(null),
      disableFromTime: new FormControl(null),
      disableTo: new FormControl(null),
      disableToTime: new FormControl(null),
      note: new FormControl(null),
    });
  }

  ngOnInit(): void {
    if (this.vehicleDisableId) {
      this.getVehicleDisable();
    }
  }

  private getVehicleDisable() {
    this._vehiclesService.getVehicleDisable$(this.vehicleDisableId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (vehicleDisable) => {
          this.setValues(vehicleDisable);
        }
      });
  }

  private setValues(vehicleDisable: VehicleDisable) {
    if (vehicleDisable) {
      let disableFrom = vehicleDisable.disabledFrom ? new Date(vehicleDisable.disabledFrom) : null;
      let disabledFromTime = disableFrom ? this._dateUtilsService.parseDateToTimeString(disableFrom) : null;
      let disableTo = vehicleDisable.disabledTo ? new Date(vehicleDisable.disabledTo) : null;
      let disabledToTime = disableTo ? this._dateUtilsService.parseDateToTimeString(disableTo) : null;

      this.form.patchValue({
        disableFrom: disableFrom,
        disableFromTime: disabledFromTime,
        disableTo: disableTo,
        disableToTime: disabledToTime,
        note: vehicleDisable.note,
      });
    }
  }

  onSubmit() {
    const request: VehicleDisableRequest = {
      vehicleId: this.vehicleId,
      note: this.form.value.note,
    };

    if (this.form.valid) {
      if (this.form.value.disableFrom && this.form.value.disableFromTime) {
        let disableFrom = this._dateUtilsService.combineDateAndTime(this.form.value.disableFrom, this.form.value.disableFromTime);
        request.disabledFrom = disableFrom;
      } else {
        request.disabledFrom = null;
      }

      if (this.form.value.disableTo && this.form.value.disableToTime) {
        let disableTo = this._dateUtilsService.combineDateAndTime(this.form.value.disableTo, this.form.value.disableToTime);
        request.disabledTo = disableTo;
      } else {
        request.disabledTo = null;
      }

      if (this.vehicleDisableId) {
        this._vehiclesService.updateVehicleDisable$(this.vehicleDisableId, request)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            next: (response) => {
              this.onUpdated();
            }
          });
      } 
      else {
        this._vehiclesService.createVehicleDisable$(request)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            next: (response) => {
              this.onUpdated();
          }
        });
      }
    }
  }

  private onUpdated() {
    this._communicationService.sendEvent();
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
