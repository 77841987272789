import { Injectable } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Observable, map, switchMap, of, concatMap } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

// Models
import { Asset } from "../models/assets/asset";
import { Booking } from "../models/booking/booking";
import { CabinetKey } from "../models/cabinet/cabinet";
import { CabinetFilter } from "../models/cabinet/cabinet-filter";
import { CabinetResponse } from "../models/cabinet/cabinet-response";
import { Headquarter } from '../models/headquarter/headquarter';
import { ImportFile } from "../models/import/import";
import { InvoicesFilter } from "../models/invoice/invoice-filter";
import { LocationModel } from "src/app/shared/models/location/location";
import { Make, Model, VersionModel } from '../models/make/make';
import { Me } from '../models/me/me';
import { Permission } from '../models/permission/permission';
import { SuppliersFilter } from '../models/supplier/supplier-filter';
import { Tag } from '../models/tag/tag';
import { Task } from "../models/task/task";
import { TasksFilter } from "../models/task/task-filter";
import { UserModel } from '../models/user/users';
import { Vehicle } from '../models/vehicle/vehicle';
import { VehicleFilter } from '../models/vehicle/vehicle-filter';

// Services
import { authGuardModal } from 'src/app/core/auth.guard';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CabinetService } from '../api-services/cabinet.service';
import { InvoicesService } from '../api-services/invoices.service';
import { SuppliersService } from '../api-services/suppliers.service';
import { TasksService } from "../api-services/tasks.service";
import { TenantService } from '../api-services/tenant.service';
import { UserGroupsService } from '../api-services/user-groups.service';
import { VehiclesService } from '../api-services/vehicles.service';

// Components
import { UploadImportModalComponent } from "../components/modals/import-modal/upload-import-modal/upload-import-modal.component";
import { TaskCreateModalComponent } from "../components/modals/task-modal/task-create-modal/task-create-modal.component";
import { TaskEditModalComponent } from "../components/modals/task-modal/task-edit-modal/task-edit-modal.component";
import { VehiclesModalComponent } from "../components/modals/vehicles-modal/vehicles-modal.component";
import { AssetsModalComponent } from "../components/modals/assets-modal/assets-modal.component";
import { HeadquarterModalComponent } from '../components/modals/headquarter-modal/headquarter-modal.component';
import { LocationModalComponent } from '../components/modals/location-modal/location-modal.component';
import { TagModalComponent } from '../components/modals/tag-modal/tag-modal.component';
import { VersionModalComponent } from '../components/modals/version-modal/version-modal.component';
import { PersonModalComponent } from '../components/modals/person-modal/person-modal.component';
import { FilterTaskModalComponent } from '../components/modals/filter-modal/filter-task-modal/filter-task-modal.component';
import { VersionMakeModelActionsModalComponent } from '../components/modals/version-make-model-actions-modal/version-make-model-actions-modal.component';
import { MakeModalComponent } from '../components/modals/make-modal/make-modal.component';
import { ModelModalComponent } from '../components/modals/model-modal/model-modal.component';
import { DownloadImportModalComponent } from '../components/modals/import-modal/download-import-modal/download-import-modal.component';
import { VehicleSuspensionProgramModalComponent } from '../components/modals/vehicles-modal/vehicle-suspension-program-modal/vehicle-suspension-program-modal.component';
import { BookingFilterModalComponent } from '../components/modals/filter-modal/booking-filter-modal/booking-filter-modal.component';
import { FilterAssetModalComponent } from '../components/modals/filter-modal/filter-asset-modal/filter-asset-modal.component';
import { VehicleFilterModalComponent } from '../components/modals/filter-modal/vehicle-filter-modal/vehicle-filter-modal.component';
import { FringeBenefitModalComponent } from '../components/modals/fringe-benefit-modal/fringe-benefit-modal.component';
import { ModalReportsFilterComponent } from 'src/app/pages/reports/modal-reports-filter/modal-reports-filter.component';
import { VehicleUsagesFilterModalComponent } from '../components/modals/filter-modal/vehicle-usages-filter-modal/vehicle-usages-filter-modal.component';
import { FilterCostModalComponent } from '../components/modals/filter-modal/filter-cost-modal/filter-cost-modal.component';
import { SuppliersModalComponent } from '../components/modals/suppliers-modal/suppliers-modal.component';
import { InvoicesModalComponent } from '../components/modals/invoices-modal/invoices-modal.component';
import { MappingCabinetKeyModalComponent } from '../components/modals/mapping-cabinet-key-modal/mapping-cabinet-key-modal.component';
import { TracksModalComponent } from '../components/modals/tracks-modal/tracks-modal.component';
import { NoPermissionsModalComponent } from '../components/modals/no-permissions-modal/no-permissions-modal.component';
import { ErrorModalComponent, ErrorModalData } from '../components/modals/error-modal/error-modal.component';
import { UserGroupModalComponent } from '../components/modals/user-group-modal/user-group-modal.component';
import { PermissionModalComponent } from '../components/modals/permission-modal/permission-modal.component';
import { FilterPersonModalComponent } from '../components/modals/filter-modal/filter-person-modal/filter-person-modal.component';
import { ImportFileFilterModalComponent } from '../components/modals/import-file-filter-modal/import-file-filter-modal.component';
import { ImportManagementDataFilterModalComponent } from '../components/modals/import-management-data-filter-modal/import-management-data-filter-modal.component';
import { ImportFileModalComponent } from '../components/modals/import-file-modal/import-file-modal.component';
import { BookingModalComponent } from '../components/modals/booking-modal/booking-modal.component';
import { AssistanceModalComponent } from '../components/modals/assistance-modal/assistance-modal.component';
import { UserNotificationSettingsModalComponent } from '../components/modals/user-notification-settings-modal/user-notification-settings-modal.component';

import { TypeImporters } from "../components/modals/import-modal/upload-import-modal/type-importers";
import { PrivacyPolicyModalComponent } from '../components/modals/privacy-policy-modal/privacy-policy-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalUtilsService {

  tenantId = this._tenatService.getTenantId();
  private modalImport: BsModalRef;

  private modalEditTask: BsModalRef;

  constructor(
    private _tenatService: TenantService,
    private _modalService: BsModalService,
    private _userGroupService: UserGroupsService,
    private _vehiclesService: VehiclesService,
    private _taskService: TasksService,
    private _cabinetsService: CabinetService,
    private _suppliersService: SuppliersService,
    private _invoicesService: InvoicesService,
    private _datePipe: DatePipe,
    private _authenticationService: AuthenticationService
  ) { }

  openNoPermissionsModal(): BsModalRef {
    return this._modalService.show(NoPermissionsModalComponent, {
      class: 'modal-md',
      animated: true,
      backdrop: 'static'
    });
  }

  openErrorModal(data: ErrorModalData): BsModalRef {
    return this._modalService.show(ErrorModalComponent, {
      initialState: {
        data
      },
      class: 'modal-md',
      animated: true,
      backdrop: 'static'
    });
  }

  openPersonModal({ user, isUser = false, me }: { user?: UserModel; isUser?: boolean; me?: Me; }): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAutenticated => {
        if (isAutenticated) {
          return this._modalService.show(PersonModalComponent, {
            class: 'modal-md',
            initialState: {
              user: user ?? null,
              isUser,
              me: me ?? null,
            },
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openVehicleModal(vehicle?: Vehicle): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {

          if (vehicle) {
            let params: VehicleFilter = {
              includeMake: true,
              includeModel: true,
              includeVersion: true,
              includeHeadquarter: true,
              includeLocation: true,
              includeVehicleType: true,
              includePurchaseType: true,
              includeTags: true,
            }

            return this._vehiclesService.getVehicle$(this.tenantId, vehicle.id, params)
          } else {
            return of(null);
          }
        }
        throw new Error('User not authenticated');
      }),
      switchMap(vehicle => {
        const modalRef = this._modalService.show(VehiclesModalComponent, {
          class: 'modal-md',
          initialState: {
            vehicle: vehicle ?? null,
          },
          backdrop: 'static',
          animated: true,
        });
        return of(modalRef);
      })
    );
  }

  openBookingModal(booking?: Booking, canBookForOthers: boolean = false): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(BookingModalComponent, {
            initialState: {
              booking: booking ?? null,
              canBookForOthers,
            },
            backdrop: 'static',
            class: 'modal-lg',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }


  openCreateTaskModal(hasTCO: boolean = false, extraInfo?: ModalExtraInformation): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          return of(this._modalService.show(TaskCreateModalComponent, {
            initialState: {
              booking: extraInfo?.booking ?? null,
              user: extraInfo?.user ?? null,
              vehicle: extraInfo?.vehicle ?? null,
              headquarterId: extraInfo?.booking?.headquarterId ?? null,
              hasTCO: hasTCO,
            },
            backdrop: 'static',
            class: 'modal-lg',
            animated: true
          }));
        }
        throw new Error('User not authenticated');
      }),
      map(modalCreateTask => {
        modalCreateTask.onHidden.subscribe(() => {
          if (modalCreateTask.content.taskId) {
            this.openEditTaskModal(modalCreateTask.content.taskId).subscribe();
          }
        });
        return modalCreateTask;
      })
    );
  }

  openEditTaskModal(taskId: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          let params: TasksFilter = {
            includeTaskType: true,
            includeHeadquarter: true,
            includeUser: true,
            includeVehicle: true,
            includeAsset: true,
            includeAssignedUser: true,
            includeStartUser: true,
            includeTaskWatchers: true,
          };

          return this._taskService.getTask$(this.tenantId, taskId, params).pipe(
            switchMap((response: Task) => {
              if (response) {
                response.expirationDate = this._datePipe.transform(response.expirationDate, 'dd-MM-yyyy')?.toString();

                let taskRequest = this._taskService.mapTaskToTaskRequest(response);

                this.modalEditTask = this._modalService.show(TaskEditModalComponent, {
                  initialState: {
                    task: response,
                    taskRequest,
                    dropdownId: `dropdown-state-task-${taskId}`,
                  },
                  backdrop: 'static',
                  class: 'modal-lg',
                  animated: true
                });
                return of(this.modalEditTask);
              }

              return of(null);
            })
          );
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openAssetModal(asset?: Asset): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(AssetsModalComponent, {
            initialState: {
              asset: asset ?? null
            },
            backdrop: 'static',
            class: 'modal-md',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openSupplierModal(supplierId?: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          if (supplierId) {
            const params: SuppliersFilter = {
              includeTags: true,
            }
            return this._suppliersService.getSupplier$(this.tenantId, supplierId, params);
          } else {
            return of(null);
          }
        }
        throw new Error('User not authenticated');
      }),
      switchMap(supplier => {
        const modalRef = this._modalService.show(SuppliersModalComponent, {
          class: 'modal-md',
          initialState: { supplier },
          backdrop: 'static',
          animated: true
        });
        return of(modalRef);
      })
    );
  }

  openInvoiceModal(invoiceId?: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          if (invoiceId) {
            const params: InvoicesFilter = {
              includeTags: true,
              includeSupplier: true,
            }
            return this._invoicesService.getInvoice$(this.tenantId, invoiceId, params);
          } else {
            return of(null);
          }
        }
        throw new Error('User not authenticated');
      }),
      switchMap(invoice => {
        const modalRef = this._modalService.show(InvoicesModalComponent, {
          class: 'modal-lg',
          initialState: { invoice },
          backdrop: 'static',
          animated: true
        });
        return of(modalRef);
      })
    );
  }

  openCreateImportModal(typeImporters?: TypeImporters): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          this.modalImport = this._modalService.show(UploadImportModalComponent, {
            class: 'modal-md',
            initialState: {
              typeImporters: typeImporters ?? null
            },
            backdrop: 'static',
            animated: true,
          });
          let importFile: ImportFile;
          this.modalImport.onHide.subscribe(() => {
            importFile = this.modalImport.content.import;
          });
          return this.modalImport;
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openImportFileModal(importFile: ImportFile): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(ImportFileModalComponent, {
            initialState: { importFile }
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openDownloadTraceImportModal(typeImporters?: TypeImporters): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          let modal = this._modalService.show(DownloadImportModalComponent, {
            class: 'modal-md',
            initialState: {
              typeImporters: typeImporters ?? null
            },
            backdrop: 'static',
            animated: true,
          });
          return modal;
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openHeadquarterModal(headquarter?: Headquarter): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(HeadquarterModalComponent, {
            initialState: {
              headquarter: headquarter ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openLocationModal(location?: LocationModel): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(LocationModalComponent, {
            initialState: {
              location: location ?? null
            },
            class: 'modal-xl',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openTagModal(tag?: Tag): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(TagModalComponent, {
            initialState: {
              tag: tag ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openMakeModelVersionActionsModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VersionMakeModelActionsModalComponent, {
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openVehicleVersionModal(version?: VersionModel): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VersionModalComponent, {
            initialState: {
              version: version ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openMakeModal(make?: Make): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(MakeModalComponent, {
            initialState: {
              make: make ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModelModal(model?: Model): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(ModelModalComponent, {
            initialState: {
              model: model ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openPrivacyPolicyModal(documentId: string, me?: Me): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(PrivacyPolicyModalComponent, {
            initialState: {
              documentId,
              me
            },
            class: 'modal-lg',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openTaskFilterModal(id: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterTaskModalComponent, {
            initialState: {
              id
            },
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openCostFilterModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterCostModalComponent, {
            class: 'modal-lg',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openBookingFilterModal(showUser: boolean = true): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(BookingFilterModalComponent, {
            class: 'modal-lg',
            backdrop: 'static',
            animated: true,
            initialState: {
              showUser
            }
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openAssetFilterModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterAssetModalComponent, {
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openPersonFilterModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterPersonModalComponent, {
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openVehicleFilterModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VehicleFilterModalComponent, {
            class: 'modal-lg',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openVehicleUsageFilterModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VehicleUsagesFilterModalComponent, {
            class: 'modal-lg',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openImportFileFilterModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(ImportFileFilterModalComponent, {
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openImportManagementDataFilterModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(ImportManagementDataFilterModalComponent, {
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openVehicleSuspensionProgramModal(vehicleId: string, vehicleDisableId?: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VehicleSuspensionProgramModalComponent, {
            initialState: {
              vehicleId: vehicleId,
              vehicleDisableId: vehicleDisableId ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openFringeBenefitModal(bookingId: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (bookingId === null) throw new Error('bookingId is null');

        if (isAuthenticated) {
          return this._modalService.show(FringeBenefitModalComponent, {
            initialState: {
              bookingId,
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openFilterReportsModal(id: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {

        if (isAuthenticated) {
          return this._modalService.show(ModalReportsFilterComponent, {
            initialState: {
              id
            },
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openCabinetKeyModal(id: string): Observable<void> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          return this._cabinetsService.getCabinetKey$(this.tenantId, id);
        }
        throw new Error('User not authenticated');
      }),
      concatMap((cabinetKey: CabinetKey) => {
        if (cabinetKey) {
          let cabinetFilter: CabinetFilter = {
            driverCabinetId: cabinetKey.driverCabinetId,
            limit: 1,
          };
          return this._cabinetsService.listCabinets$(this.tenantId, cabinetFilter).pipe(
            map((cabinetResponse: CabinetResponse) => {
              this._modalService.show(MappingCabinetKeyModalComponent, {
                initialState: {
                  cabinetKey: cabinetKey,
                  cabinet: cabinetResponse.items[0],
                },
                class: 'modal-md',
                backdrop: 'static',
                animated: true,
              });
              return;
            })
          );
        }
        return of(null);
      })
    );
  }

  openTracksModal(vehicleId: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(TracksModalComponent, {
            initialState: {
              vehicleId
            },
            class: 'modal-xl',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openUserGroupModal(userGroupId?: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          if (userGroupId) {
            return this._userGroupService.getUserGroup$(this.tenantId, userGroupId).pipe(
              map((userGroup) => {
                return this._modalService.show(UserGroupModalComponent, {
                  initialState: { userGroup },
                  class: 'modal-md',
                  backdrop: 'static',
                  animated: true,
                });
              })
            );
          }
          return of(this._modalService.show(UserGroupModalComponent, {
            class: 'modal-md',
            initialState: { userGroup: null },
            backdrop: 'static',
            animated: true,
          }));
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openPermissionModal(permissionId?: string, userGroupId?: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          if (permissionId) {
            return this._userGroupService.getPermission$(this.tenantId, permissionId).pipe(
              map((permission: Permission) => {
                return this._modalService.show(PermissionModalComponent, {
                  initialState: { permission, userGroupId },
                  class: 'modal-md',
                  backdrop: 'static',
                  animated: true,
                });
              })
            );
          }
          return of(this._modalService.show(PermissionModalComponent, {
            initialState: { permission: null, userGroupId },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          }));
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openAssistanceModal(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        return this._modalService.show(AssistanceModalComponent, {
          class: 'modal-md',
          backdrop: 'static',
          animated: true,
        });
      })
    );
  }

  openUserNotificationSettingsModal(userId?: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(UserNotificationSettingsModalComponent, {
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
            initialState: {
              userId
            }
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }
}

export interface ModalExtraInformation {
  booking?: Booking;
  vehicle?: Vehicle;
  user?: UserModel;
}
